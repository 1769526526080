cs.ns("app.trait.sv.root");
app.trait.sv.root.backendservices = cs.trait({
    dynamics: {
        loginService:        '%s/sv/login',
        accessService:       '%s/sv/access',
        channelRService:     '%s/sv/channel',
        contentListRService: '%s/sv/channel/%s/content',
        contentRService:     '%s/sv/content/%s',
        readUrlMapService:   '%s/sv/urlmap/%s',
        loggingService:      '%s/sv/logging'
    },
    protos:   {

        prepare () {
            this.base();

            this.registerService("POST", "login", (username, password, token, callback) => {
                const obj = {}
                if (username) obj.username = username
                if (password) obj.password = password
                if (token) obj.token = token
                const data       = obj
                const options    = { 'dataType': 'json' }
                const serviceURL = sprintf(this.loginService, this.serviceRoot)
                return { data: data, options: options, serviceURL: serviceURL, callback: callback }
            }, (error, text, xhr, callback) => {
                try {
                    const objs = text.length > 0 ? JSON.parse(text) : {};
                    if (this.hasError(objs)) {
                        this.handleFunctionalError(objs, callback);
                    } else {
                        callback(this.SUCCESS, objs);
                    }
                } catch (e) {
                    console.error(e);
                    // technical it is an error as well, if the response couldn't be parsed
                    callback(false, { error: this.messageId }, false);
                }

            });

            this.registerService('GET', 'access', callback => {
                const serviceURL = sprintf(this.accessService, this.serviceRoot)
                return { serviceURL: serviceURL, callback: callback }
            }, (error, text, xhr, callback) => {
                try {
                    const objs = text.length > 0 ? JSON.parse(text) : {};
                    if (this.hasError(objs)) {
                        callback(this.ERROR, objs);
                    } else {
                        callback(this.SUCCESS, objs);
                    }
                } catch (e) {
                    console.error(e);
                    // technical it is an error as well, if the response couldn't be parsed
                    callback(false, { error: this.messageId }, false);

                }
            })

            this.registerService('GET', 'readChannels', callback => {
                const serviceURL = sprintf(this.channelRService, this.serviceRoot)
                return { serviceURL: serviceURL, callback: callback }
            })

            this.registerService('GET', 'readContentList', (channelId, callback) => {
                const serviceURL = sprintf(this.contentListRService, this.serviceRoot, channelId)
                return { serviceURL: serviceURL, callback: callback }
            })

            this.registerService('GET', 'readUrlMap', (hash, callback) => {
                const serviceURL = sprintf(this.readUrlMapService, this.serviceRoot, hash)
                return { serviceURL: serviceURL, callback: callback }
            })

            this.registerService('PUT', 'sendMessageForRemoteLogging', (message, level, callback) => {
                const serviceURL = sprintf(this.loggingService, this.serviceRoot)
                return { serviceURL: serviceURL, callback: callback, data: { message, level } }
            })
        }
    }
});
